import React from 'react'
import PropTypes from 'prop-types'
import DetailLayout from '../../containers/layout/detail-layout'

const BoardDetail = ({ location, pageContext }) => (
  <DetailLayout
    location={location}
    pageContext={pageContext}
    pageData={pageContext}
  />
)

BoardDetail.propTypes = {
  headingStyle: PropTypes.object,
}

export default BoardDetail
